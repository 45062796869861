import axios from "axios";
import { getAccessToken } from "./auth";

const baseUrl = `https://smith.api.fybrlabs.frontier.com/Prod`;

export const getSmithResults = async (serialNumber, pageSize = 20, page = 0) => {
	console.log('getSmithResults');

	try {
		const token = await getAccessToken();
		const url = `${baseUrl}/v1/${serialNumber}/results?limit=${pageSize}&page=${page}`;
		const response = await axios.get(url, {headers: {'auth-token': token }});
		return response.data;
	} catch(e) {
		console.log('getSmithResults ', e.message);
		throw e;
	}
}

export const getSmithSchedules = async (serialNumber) => {
	console.log('getSmithSchedules');

	try {
		const token = await getAccessToken();
		const url = `${baseUrl}/v1/${serialNumber}/schedules?psi=true`;
		const response = await axios.get(url, {headers: {'auth-token': token }});
		return response.data;
	} catch(e) {
		console.log('getSmithSchedules ', e.message);
		throw e;
	}
}

export const putSmithSchedule = async (serialNumber, payload) => {
	console.log('putSmithSchedule');

	try {
		const token = await getAccessToken();
		const url = `${baseUrl}/v1/${serialNumber}/schedules`;
		const response = await axios.post(url, payload, {headers: {'auth-token': token }});
		return response.data;
	} catch(e) {
		console.log('putSmithSchedule ', e.message);
		throw e;
	}
}

export const getSmithConfig = async () => {
	console.log('getSmithConfig');

	try {
		const token = await getAccessToken();
		const url = `${baseUrl}/v1/config`;
		const response = await axios.get(url, {headers: {'auth-token': token }});
		return response.data;
	} catch(e) {
		console.log('getSmithConfig ', e.message);
		throw e;
	}
}

export const setSmithConfig = async (config) => {
	console.log('getSmithConfig');

	try {
		const token = await getAccessToken();
		const url = `${baseUrl}/v1/config`;
		const response = await axios.patch(url, config,  {headers: {'auth-token': token }});
		return response.data;
	} catch(e) {
		console.log('setSmithConfig ', e.message);
		throw e;
	}
}